import { post, get, put, _delete } from '@/lin/plugin/axios'

class Banner {
  // 添加广告位
  static async addBanner(data) {
    const res = await post('/v1/banner', data)
    return res
  }

  // 获取广告位详情
  static async getBanner(id) {
    const res = await get(`/v1/banner/${id}`)
    return res
  }

  // 修改广告位
  static async editBanner(id, data) {
    const res = await put(`/v1/banner/${id}`, data)
    return res
  }

  // 删除广告位
  static async deleteBanner(id) {
    const res = await _delete(`/v1/banner/${id}`)
    return res
  }

  // 获取广告位列表
  static async getBanners(page = 0, count = 10) {
    const res = await get('/v1/banner', {
      page,
      count,
    })
    return res
  }

  // 添加广告
  static async addBannerItem(data) {
    const res = await post('/v1/banner_item', data)
    return res
  }

  // 获取广告详情
  static async getBannerItem(id) {
    const res = await get(`/v1/banner_item/${id}`)
    return res
  }

  // 修改广告
  static async editBannerItem(id, data) {
    const res = await put(`/v1/banner_item/${id}`, data)
    return res
  }

  // 删除广告
  static async deleteBannerItem(id) {
    const res = await _delete(`/v1/banner_item/${id}`)
    return res
  }

  // 获取广告详情
  static async getBannersItem(page = 0, count = 10) {
    const res = await get('/v1/banner_item/page', {
      page,
      count,
    })
    return res
  }
}

export default Banner
